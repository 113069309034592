<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class=" d-sm-flex justify-content-between align-items-center pb-1  ">
          <h4 class="">Coupons/Create</h4>
          <div>
            <router-link :to="{...previousRoute}">
              <span class="glow d-flex align-items-center"><i class='bx bx-undo'></i>&nbsp;Back</span>
            </router-link>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-3">
                <div class="form-group">
                  <div class="controls">
                    <label>Coupon Name </label>
                    <input v-model="postCouponData.name" type="text" class="form-control"
                           placeholder="Example: Black Friday" name="">
                  </div>
                  <div class="text-danger" v-if="errors.name">{{ errors.name }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-3">
                <div class="form-group">
                  <div class="controls">
                    <label>Coupon Code </label>
                    <input v-model="postCouponData.code" type="text" class="form-control"
                           placeholder="Example: GEEKS2022">
                  </div>
                  <div class="text-danger" v-if="errors.code">{{ errors.code }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-3">
                <div class="form-group">
                  <label>Apply On</label>
                  <VueMultiselect v-model="selectedApplyOn" class="" :options="applyOnOptions"
                                  :close-on-select="true"
                                  placeholder="Select status" label="name" track-by="name" :show-labels="false"
                                  :allow-empty="false"/>
                  <div class="text-danger" v-if="errors.applyOn">{{ errors.applyOn }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-3">
                <div class="form-group">
                  <div class="controls">
                    <label>Discount Type</label>
                    <div class="" style="margin-top: .5rem">
                      <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                        <div>
                          <input :disabled="selectedApplyOn.value === 2" v-model="discountTypeStatus" type="checkbox"
                                 class="custom-control-input" checked id="discountTypeStatusCheckbox">
                          <label class="custom-control-label mr-1" for="discountTypeStatusCheckbox"></label>
                        </div>
                        <span class="font-medium-1">{{ discountTypeStatus ? "Fixed Amount" : "Percentage" }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="text-danger" v-if="errors.discountType">{{ errors.discountType }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-3">
                <div class="form-group">
                  <div class="controls">
                    <label>
                      {{ selectedApplyOn.value === 2 ? "Grand Total" : "Discount" }}
                      {{ discountTypeStatus === true ? 'Amount' : 'Percentage' }}
                    </label>
                    <input v-model="discountValue" type="text" class="form-control"
                           :placeholder="discountTypeStatus === true
                           ? `Enter ${selectedApplyOn.value === 2 ? 'Grand Total' : 'Discount'} Amount`
                           : 'Enter Discount Percentage'"/>
                  </div>
                  <div class="text-danger" v-if="errors.discount">{{ errors.discount }}</div>
                </div>

              </div>
              <div class="col-12 col-sm-12 col-md-3">
                <div class="form-group">
                  <div class="controls">
                    <label>Activation Amount</label>
                    <input v-model="activationAmountValue" type="text" class="form-control"
                           placeholder="Enter Activation Amount">
                  </div>
                  <div class="text-danger" v-if="errors.activationAmount">{{ errors.activationAmount }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-3">
                <div class="form-group">
                  <div class="controls">
                    <label>Start Date </label>
                    <div class="d-flex align-items-center date-picker-wrapper">
                      <div class="d-flex align-items-center">
                        <i class='bx bx-calendar'></i>
                      </div>
                      <div class="w-100 pl-1">
                        <div class="w-100 ">
                          <DatePicker v-model="postCouponData.start_at" :update-on-input="true"
                                      mode="dateTime"
                                      :min-date="new Date()"
                                      :masks="{ inputDateTime: ['DD MMMM YYYY hh:mm A'] }"
                                      :model-config="{ type: 'string', mask: 'YYYY-MM-DD HH:mm:ss'}">
                            <template v-slot="{ inputValue, inputEvents }">
                              <input class="form-control" :value="inputValue" v-on="inputEvents"/>
                            </template>
                          </DatePicker>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-danger" v-if="errors.startAt">{{ errors.startAt }}</div>
                </div>

              </div>
              <div class="col-12 col-sm-12 col-md-3">
                <div class="form-group">
                  <div class="controls">
                    <label>End Date <i @click="showEndTimeInfo" class='bx bx-info-circle bx-xs'></i></label>
                    <div class="d-flex align-items-center date-picker-wrapper">
                      <div class="d-flex align-items-center">
                        <i class='bx bx-calendar'></i>
                      </div>
                      <div class="w-100 pl-1">
                        <div class="w-100 ">
                          <DatePicker v-model="postCouponData.end_at" :update-on-input="true"
                                      mode="dateTime"
                                      :min-date="new Date()"
                                      :masks="{ inputDateTime: ['DD MMMM YYYY hh:mm A'] }"
                                      :model-config="{ type: 'string', mask: 'YYYY-MM-DD HH:mm:ss'}">
                            <template v-slot="{ inputValue, inputEvents }">
                              <input class="form-control" :value="inputValue" v-on="inputEvents"/>
                            </template>
                          </DatePicker>
                        </div>
                      </div>
                    </div>
                  </div>
                  <small id="show-end-time-info-text">End date-time of the coupon. Keep blank for no end time.</small>
                  <div class="text-danger" v-if="errors.endAt">{{ errors.endAt }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-3">
                <div class="form-group">
                  <div class="controls">
                    <div class="d-flex align-items-center">
                      <label>Uses Per Coupon (Total) <i @click="showUsagePerCouponInfo"
                                                class='bx bx-info-circle bx-xs'></i></label>
                    </div>
                    <input v-model="postCouponData.usage_per_coupon" type="number" class="form-control"
                           placeholder="Enter Uses Per Coupon">
                    <small id="show-coupon-info-text">Limits how many times the coupon can be used by any user. Keep
                      blank for unlimited.</small>
                  </div>
                  <div class="text-danger" v-if="errors.usagePerCoupon">{{ errors.usagePerCoupon }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-3">
                <div class="form-group">
                  <div class="controls">
                    <div class="d-flex align-items-center">
                      <label>Usage per user (User Usage Limit) <i @click="showUsagePerUserInfo"
                                               class='bx bx-info-circle bx-xs'></i></label>
                    </div>
                    <input v-model="postCouponData.usage_per_user" type="number" class="form-control"
                           placeholder="Enter Usage Per User">
                    <small id="show-user-info-text">Limits how many times a specific user can be use the coupon. Keep
                      blank for unlimited.</small>
                  </div>
                  <div class="text-danger" v-if="errors.usagePerUser">{{ errors.usagePerUser }}</div>

                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-3">
                <div class="form-group">
                  <div class="controls">
                    <label>Type</label>
                    <div class="" style="margin-top: .5rem">
                      <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                        <div>
                          <input v-model="typeStatus" type="checkbox" class="custom-control-input"
                                 id="typeStatusCheckbox">
                          <label class="custom-control-label mr-1" for="typeStatusCheckbox"></label>
                        </div>
                        <span class="font-medium-1">{{ typeStatus ? "Global" : "Assigned Only" }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="text-danger" v-if="errors.type">{{ errors.type }}</div>

                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-3">
                <div class="form-group">
                  <div class="controls">
                    <label>Status</label>
                    <div class="" style="margin-top: .5rem">
                      <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                        <div>
                          <input v-model="couponStatus" type="checkbox" class="custom-control-input"
                                 id="couponStatusCheckbox">
                          <label class="custom-control-label mr-1" for="couponStatusCheckbox"></label>
                        </div>
                        <span class="font-medium-1">{{ couponStatus ? "Active" : "Inactive" }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="text-danger" v-if="errors.status">{{ errors.status }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-3">
                <div class="form-group">
                  <div class="controls">
                    <label>Internal Coupon</label>
                    <div class="" style="margin-top: .5rem">
                      <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                        <div>
                          <input v-model="internalCoupon" type="checkbox" class="custom-control-input"
                                 id="internalCouponCheckbox">
                          <label class="custom-control-label mr-1" for="internalCouponCheckbox"></label>
                        </div>
                        <span class="font-medium-1">{{ internalCoupon ? "Active" : "Inactive" }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="text-danger" v-if="errors.internalCoupon">{{ errors.internalCoupon }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-3">
                <div class="form-group">
                  <div class="controls">
                    <label>Website Visibility</label>
                    <div class="" style="margin-top: .5rem">
                      <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                        <div>
                          <input v-model="websiteVisibility" type="checkbox" class="custom-control-input"
                                 id="websiteVisibilityCheckbox">
                          <label class="custom-control-label mr-1" for="websiteVisibilityCheckbox"></label>
                        </div>
                        <span class="font-medium-1">{{ websiteVisibility ? "Active" : "Inactive" }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="text-danger" v-if="errors.websiteVisibility">{{ errors.websiteVisibility }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-3">
                <div class="form-group">
                  <div class="controls">
                    <label>Same Day Restriction</label>
                    <div class="" style="margin-top: .5rem">
                      <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                        <div>
                          <input v-model="sameDayRestriction" type="checkbox" class="custom-control-input"
                                 id="sameDayRestrictionCheckbox">
                          <label class="custom-control-label mr-1" for="sameDayRestrictionCheckbox"></label>
                        </div>
                        <span class="font-medium-1">{{ sameDayRestriction ? "Active" : "Inactive" }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="text-danger" v-if="errors.sameDayRestriction">{{ errors.sameDayRestriction }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-3">
                <div class="form-group">
                  <div class="controls">
                    <label>Weekend Restriction</label>
                    <div class="" style="margin-top: .5rem">
                      <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                        <div>
                          <input v-model="weekendRestriction" type="checkbox" class="custom-control-input"
                                 id="weekendRestrictionCheckbox">
                          <label class="custom-control-label mr-1" for="weekendRestrictionCheckbox"></label>
                        </div>
                        <span class="font-medium-1">{{ weekendRestriction ? "Active" : "Inactive" }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="text-danger" v-if="errors.weekendRestriction">{{ errors.weekendRestriction }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-3">
                <div class="form-group">
                  <div class="controls">
                    <label>Holiday Restriction</label>
                    <div class="" style="margin-top: .5rem">
                      <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                        <div>
                          <input v-model="holidayRestriction" type="checkbox" class="custom-control-input"
                                 id="holidayRestrictionCheckbox">
                          <label class="custom-control-label mr-1" for="holidayRestrictionCheckbox"></label>
                        </div>
                        <span class="font-medium-1">{{ holidayRestriction ? "Active" : "Inactive" }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="text-danger" v-if="errors.holidayRestriction">{{ errors.holidayRestriction }}</div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-9">
                <div class="form-group">
                  <div class="controls">
                    <div class="d-flex align-items-center">
                      <label>Restricted Post Codes <i @click="showRestrictedPostCodeInfo"
                                                      class='bx bx-info-circle bx-xs'></i></label>
                    </div>
                    <input v-model="postCouponData.restricted_post_code" type="text" class="form-control"
                           placeholder="Enter Comma Separated Post Code">
                    <small id="show-restricted-post-code-text">The coupon will not be valid for restricted post code areas. If multiple areas please input the post code by comma separated.</small>
                  </div>
                  <div class="text-danger" v-if="errors.restrictedPostCode">{{ errors.restrictedPostCode }}</div>

                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-12">
                <div class="form-group">
                  <div>
                    <label>Usage Condition</label>
                    <textarea v-model="postCouponData.usage_condition" id="usageCondition"
                              class="form-control"></textarea>
                  </div>
                  <div class="text-danger" v-if="errors.usageCondition">{{ errors.usageCondition }}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 ">
                <div class="d-flex justify-content-end">
                  <button @click="createSingleCoupon" type="button" class="btn btn-primary update-todo px-4">Create
                    Coupon
                  </button>
                </div>
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue';
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import {DatePicker} from 'v-calendar';
import VueMultiselect from "vue-multiselect";
import {mapActions, mapGetters} from "vuex";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";

export default {
  components: {
    AppLayout,
    DatePicker,
    VueMultiselect,
  },
  mixins: [ShowToastMessage, Loader, Authorization],
  name: "CreateCoupon",
  data() {
    return {
      postCouponData: {
        creator_id: '',
        name: '',
        code: '',
        type: 1,
        discount_type: 1,
        discount: '',
        activation_amount: '',
        apply_on: 0,
        start_at: '',
        end_at: '',
        usage_per_coupon: '',
        usage_per_user: '',
        status: 1,
        internal_coupon: 0,
        website_visibility: 0,
        weekend_restriction: 0,
        same_day_restriction: 0,
        holiday_restriction: 0,
        restricted_post_code: null,
        usage_condition: null,
      },

      activationAmountValue: 0.00,
      discountValue: 0.00,
      typeStatus: true,
      discountTypeStatus: true,
      selectedApplyOn: {
        value: 0,
        name: 'Subtotal'
      },
      couponStatus: true,
      internalCoupon: false,
      websiteVisibility: false,
      sameDayRestriction: false,
      weekendRestriction: false,
      holidayRestriction: false,

      errors: {
        name: null,
        code: null,
        type: null,
        discount: null,
        discountType: null,
        activationAmount: null,
        applyOn: null,
        startAt: null,
        endAt: null,
        status: null,
        internalCoupon: null,
        websiteVisibility: null,
        sameDayRestriction: null,
        weekendRestriction: null,
        holidayRestriction: null,
        restrictedPostCode: null,
        usageCondition: null,
        usagePerCoupon: null,
        usagePerUser: null
      },

    }
  },
  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',
      authUser: 'appAuthLogin/authUser',
    }),
    redirectRouteName() {
      return this.can('coupon-view-any') ? 'appCouponList' : 'appCouponCreate';
    },
    applyOnOptions() {
      return [
        {value: 0, name: 'Subtotal'},
        {value: 1, name: 'Shipping Fee'},
        {value: 2, name: 'Flat Price'},
      ]
    }
  },
  watch: {
    discountValue(discountValue) {
      this.postCouponData.discount = this.discountTypeStatus ? discountValue * 100 : discountValue * 1;
    },
    activationAmountValue(activationAmountValue) {
      this.postCouponData.activation_amount = activationAmountValue * 100;
    },
    couponStatus(couponStatus) {
      this.postCouponData.status = couponStatus ? 1 : 0;
    },
    internalCoupon(internalCoupon) {
      this.postCouponData.internal_coupon = internalCoupon ? 1 : 0;
      if (internalCoupon) {
        this.websiteVisibility = !internalCoupon;
        this.postCouponData.website_visibility = internalCoupon ? 0 : 1;
      }
    },
    websiteVisibility(websiteVisibility) {
      this.postCouponData.website_visibility = websiteVisibility ? 1 : 0;
      if (websiteVisibility) {
        this.internalCoupon = !websiteVisibility;
        this.postCouponData.internal_coupon = websiteVisibility ? 0 : 1;
      }

    },
    sameDayRestriction(sameDayRestriction) {
      this.postCouponData.same_day_restriction = sameDayRestriction ? 1 : 0;
    },
    weekendRestriction(weekendRestriction) {
      this.postCouponData.weekend_restriction = weekendRestriction ? 1 : 0;
    },
    holidayRestriction(holidayRestriction) {
      this.postCouponData.holiday_restriction = holidayRestriction ? 1 : 0;
    },
    typeStatus(typeStatus) {
      this.postCouponData.type = typeStatus ? 1 : 0;
    },
    discountTypeStatus(discountTypeStatus) {
      this.postCouponData.discount_type = discountTypeStatus ? 1 : 0
      this.postCouponData.discount = this.discountTypeStatus ? this.discountValue * 100 : this.discountValue;
    },
    selectedApplyOn(selectedApplyOn) {
      this.postCouponData.apply_on = selectedApplyOn.value;

      if (this.postCouponData.apply_on === 2) {
        this.discountTypeStatus = true;
        this.postCouponData.discount = this.discountValue * 100
      }
    }
  },
  methods: {
    ...mapActions({
      postCoupon: 'appCoupons/postCoupon',
    }),
    async createSingleCoupon() {
      this.postCouponData.creator_id = this.authUser.id;
      this.loader(true);
      await this.postCoupon(this.postCouponData).then(async (response) => {
        this.loader(false);
        if (response.status === 201) {
          const toastObj = {message: 'Coupon created successful.', type: 'success'};
          this.showToastMessage(toastObj);

          await this.$router.replace({
            name: this.redirectRouteName,
          });
        }
        this.errors.name = response?.errors?.name?.[0] ?? '';
        this.errors.code = response?.errors?.code?.[0] ?? '';
        this.errors.type = response?.errors?.type?.[0] ?? '';
        this.errors.discount = response?.errors?.discount?.[0] ?? '';
        this.errors.discountType = response?.errors?.discount_type?.[0] ?? '';
        this.errors.activationAmount = response?.errors?.activation_amount?.[0] ?? '';
        this.errors.applyOn = response?.errors?.apply_on?.[0] ?? '';
        this.errors.startAt = response?.errors?.start_at?.[0] ?? '';
        this.errors.endAt = response?.errors?.end_at?.[0] ?? '';
        this.errors.status = response?.errors?.status?.[0] ?? '';
        this.errors.internalCoupon = response?.errors?.internal_coupon?.[0] ?? '';
        this.errors.websiteVisibility = response?.errors?.website_visibility?.[0] ?? '';
        this.errors.sameDayRestriction = response?.errors?.same_day_restriction?.[0] ?? '';
        this.errors.weekendRestriction = response?.errors?.weekend_restriction?.[0] ?? '';
        this.errors.holidayRestriction = response?.errors?.holiday_restriction?.[0] ?? '';
        this.errors.usageCondition = response?.errors?.usage_condition?.[0] ?? '';
        this.errors.restrictedPostCode = response?.errors?.restricted_post_code?.[0] ?? '';
        this.errors.usagePerCoupon = response?.errors?.usage_per_coupon?.[0] ?? '';
        this.errors.usagePerUser = response?.errors?.usage_per_user?.[0] ?? '';

        if (response.message) {
          this.showToastMessage(response);
        }
      });
    },
    showEndTimeInfo() {
      const showEndTimeInfo = document.getElementById("show-end-time-info-text");
      showEndTimeInfo.style.display = showEndTimeInfo.style.display === "none" ? "block" : "none";
    },
    showUsagePerCouponInfo() {
      const showCouponInfo = document.getElementById("show-coupon-info-text");
      if (showCouponInfo.style.display === "none") {
        showCouponInfo.style.display = "block";
      } else {
        showCouponInfo.style.display = "none";
      }
    },
    showUsagePerUserInfo() {
      const showInfo = document.getElementById("show-user-info-text");
      if (showInfo.style.display === "none") {
        showInfo.style.display = "block";
      } else {
        showInfo.style.display = "none";
      }
    },
    showRestrictedPostCodeInfo() {
      const showInfo = document.getElementById("show-restricted-post-code-text");
      if (showInfo.style.display === "none") {
        showInfo.style.display = "block";
      } else {
        showInfo.style.display = "none";
      }
    },
  }
}
</script>

<style scoped>
#show-coupon-info-text {
  font-size: 12px;
  display: none;
}

#show-user-info-text {
  font-size: 12px;
  display: none;
}
#show-restricted-post-code-text {
  font-size: 12px;
  display: none;
}

#show-end-time-info-text {
  font-size: 12px;
  display: none;
}


</style>
